/* ticker/symbol, color, dark color */
/* build classes */
/* generic */
[data-theme=dark] .act-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .act-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .act-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .act-border-promo {
  border: 3px solid #6c757d !important;
}

[data-theme=dark] .act-border-highlight {
  border: 3px solid #6c757d;
  box-shadow: 0 0 20px 0 #6c757d !important;
}

[data-theme=dark] .act-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .act-button {
  background-color: #6c757d;
  color: #fff;
}

[data-theme=dark] .act-button:hover {
  box-shadow: 0 0 10px 0 #6c757d !important;
}

[data-theme=dark] .act-header {
  border-bottom: 2px solid #6c757d;
}

[data-theme=dark] .act-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.act,
.nav-pills > li > a:hover .act,
.nav-pills > li > a:focus .act {
  color: #6c757d;
}

[data-theme=dark] .nav-pills .nav-link.act.active,
.nav-pills .nav-link:hover.act.active,
.nav-pills .nav-link:focus.act.active {
  color: #e5e5e5;
  background-color: #6c757d;
}

/* generic */
[data-theme=dark] .btc-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .btc-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .btc-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .btc-border-promo {
  border: 3px solid #FF9900 !important;
}

[data-theme=dark] .btc-border-highlight {
  border: 3px solid #FF9900;
  box-shadow: 0 0 20px 0 #FF9900 !important;
}

[data-theme=dark] .btc-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .btc-button {
  background-color: #FF9900;
  color: #fff;
}

[data-theme=dark] .btc-button:hover {
  box-shadow: 0 0 10px 0 #FF9900 !important;
}

[data-theme=dark] .btc-header {
  border-bottom: 2px solid #FF9900;
}

[data-theme=dark] .btc-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.btc,
.nav-pills > li > a:hover .btc,
.nav-pills > li > a:focus .btc {
  color: #FF9900;
}

[data-theme=dark] .nav-pills .nav-link.btc.active,
.nav-pills .nav-link:hover.btc.active,
.nav-pills .nav-link:focus.btc.active {
  color: #e5e5e5;
  background-color: #FF9900;
}

/* generic */
[data-theme=dark] .eth-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .eth-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .eth-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .eth-border-promo {
  border: 3px solid #6a89a5 !important;
}

[data-theme=dark] .eth-border-highlight {
  border: 3px solid #6a89a5;
  box-shadow: 0 0 20px 0 #6a89a5 !important;
}

[data-theme=dark] .eth-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .eth-button {
  background-color: #6a89a5;
  color: #fff;
}

[data-theme=dark] .eth-button:hover {
  box-shadow: 0 0 10px 0 #6a89a5 !important;
}

[data-theme=dark] .eth-header {
  border-bottom: 2px solid #6a89a5;
}

[data-theme=dark] .eth-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.eth,
.nav-pills > li > a:hover .eth,
.nav-pills > li > a:focus .eth {
  color: #6a89a5;
}

[data-theme=dark] .nav-pills .nav-link.eth.active,
.nav-pills .nav-link:hover.eth.active,
.nav-pills .nav-link:focus.eth.active {
  color: #e5e5e5;
  background-color: #6a89a5;
}

/* generic */
[data-theme=dark] .bcc-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .bcc-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .bcc-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .bcc-border-promo {
  border: 3px solid #85bb65 !important;
}

[data-theme=dark] .bcc-border-highlight {
  border: 3px solid #85bb65;
  box-shadow: 0 0 20px 0 #85bb65 !important;
}

[data-theme=dark] .bcc-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .bcc-button {
  background-color: #85bb65;
  color: #fff;
}

[data-theme=dark] .bcc-button:hover {
  box-shadow: 0 0 10px 0 #85bb65 !important;
}

[data-theme=dark] .bcc-header {
  border-bottom: 2px solid #85bb65;
}

[data-theme=dark] .bcc-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.bcc,
.nav-pills > li > a:hover .bcc,
.nav-pills > li > a:focus .bcc {
  color: #85bb65;
}

[data-theme=dark] .nav-pills .nav-link.bcc.active,
.nav-pills .nav-link:hover.bcc.active,
.nav-pills .nav-link:focus.bcc.active {
  color: #e5e5e5;
  background-color: #85bb65;
}

/* generic */
[data-theme=dark] .dash-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .dash-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .dash-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .dash-border-promo {
  border: 3px solid #1c75bc !important;
}

[data-theme=dark] .dash-border-highlight {
  border: 3px solid #1c75bc;
  box-shadow: 0 0 20px 0 #1c75bc !important;
}

[data-theme=dark] .dash-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .dash-button {
  background-color: #1c75bc;
  color: #fff;
}

[data-theme=dark] .dash-button:hover {
  box-shadow: 0 0 10px 0 #1c75bc !important;
}

[data-theme=dark] .dash-header {
  border-bottom: 2px solid #1c75bc;
}

[data-theme=dark] .dash-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.dash,
.nav-pills > li > a:hover .dash,
.nav-pills > li > a:focus .dash {
  color: #1c75bc;
}

[data-theme=dark] .nav-pills .nav-link.dash.active,
.nav-pills .nav-link:hover.dash.active,
.nav-pills .nav-link:focus.dash.active {
  color: #e5e5e5;
  background-color: #1c75bc;
}

/* generic */
[data-theme=dark] .bsv-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .bsv-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .bsv-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .bsv-border-promo {
  border: 3px solid #e9b200 !important;
}

[data-theme=dark] .bsv-border-highlight {
  border: 3px solid #e9b200;
  box-shadow: 0 0 20px 0 #e9b200 !important;
}

[data-theme=dark] .bsv-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .bsv-button {
  background-color: #e9b200;
  color: #fff;
}

[data-theme=dark] .bsv-button:hover {
  box-shadow: 0 0 10px 0 #e9b200 !important;
}

[data-theme=dark] .bsv-header {
  border-bottom: 2px solid #e9b200;
}

[data-theme=dark] .bsv-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.bsv,
.nav-pills > li > a:hover .bsv,
.nav-pills > li > a:focus .bsv {
  color: #e9b200;
}

[data-theme=dark] .nav-pills .nav-link.bsv.active,
.nav-pills .nav-link:hover.bsv.active,
.nav-pills .nav-link:focus.bsv.active {
  color: #e5e5e5;
  background-color: #e9b200;
}

/* generic */
[data-theme=dark] .zec-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .zec-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .zec-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .zec-border-promo {
  border: 3px solid #CB842A !important;
}

[data-theme=dark] .zec-border-highlight {
  border: 3px solid #CB842A;
  box-shadow: 0 0 20px 0 #CB842A !important;
}

[data-theme=dark] .zec-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .zec-button {
  background-color: #CB842A;
  color: #fff;
}

[data-theme=dark] .zec-button:hover {
  box-shadow: 0 0 10px 0 #CB842A !important;
}

[data-theme=dark] .zec-header {
  border-bottom: 2px solid #CB842A;
}

[data-theme=dark] .zec-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.zec,
.nav-pills > li > a:hover .zec,
.nav-pills > li > a:focus .zec {
  color: #CB842A;
}

[data-theme=dark] .nav-pills .nav-link.zec.active,
.nav-pills .nav-link:hover.zec.active,
.nav-pills .nav-link:focus.zec.active {
  color: #e5e5e5;
  background-color: #CB842A;
}

/* generic */
[data-theme=dark] .xmr-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .xmr-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .xmr-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .xmr-border-promo {
  border: 3px solid #ff7519 !important;
}

[data-theme=dark] .xmr-border-highlight {
  border: 3px solid #ff7519;
  box-shadow: 0 0 20px 0 #ff7519 !important;
}

[data-theme=dark] .xmr-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .xmr-button {
  background-color: #ff7519;
  color: #fff;
}

[data-theme=dark] .xmr-button:hover {
  box-shadow: 0 0 10px 0 #ff7519 !important;
}

[data-theme=dark] .xmr-header {
  border-bottom: 2px solid #ff7519;
}

[data-theme=dark] .xmr-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.xmr,
.nav-pills > li > a:hover .xmr,
.nav-pills > li > a:focus .xmr {
  color: #ff7519;
}

[data-theme=dark] .nav-pills .nav-link.xmr.active,
.nav-pills .nav-link:hover.xmr.active,
.nav-pills .nav-link:focus.xmr.active {
  color: #e5e5e5;
  background-color: #ff7519;
}

/* generic */
[data-theme=dark] .ltc-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .ltc-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .ltc-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .ltc-border-promo {
  border: 3px solid #345d9d !important;
}

[data-theme=dark] .ltc-border-highlight {
  border: 3px solid #345d9d;
  box-shadow: 0 0 20px 0 #345d9d !important;
}

[data-theme=dark] .ltc-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .ltc-button {
  background-color: #345d9d;
  color: #fff;
}

[data-theme=dark] .ltc-button:hover {
  box-shadow: 0 0 10px 0 #345d9d !important;
}

[data-theme=dark] .ltc-header {
  border-bottom: 2px solid #345d9d;
}

[data-theme=dark] .ltc-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.ltc,
.nav-pills > li > a:hover .ltc,
.nav-pills > li > a:focus .ltc {
  color: #345d9d;
}

[data-theme=dark] .nav-pills .nav-link.ltc.active,
.nav-pills .nav-link:hover.ltc.active,
.nav-pills .nav-link:focus.ltc.active {
  color: #e5e5e5;
  background-color: #345d9d;
}

/* generic */
[data-theme=dark] .btg-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .btg-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .btg-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .btg-border-promo {
  border: 3px solid #db9a1e !important;
}

[data-theme=dark] .btg-border-highlight {
  border: 3px solid #db9a1e;
  box-shadow: 0 0 20px 0 #db9a1e !important;
}

[data-theme=dark] .btg-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .btg-button {
  background-color: #db9a1e;
  color: #fff;
}

[data-theme=dark] .btg-button:hover {
  box-shadow: 0 0 10px 0 #db9a1e !important;
}

[data-theme=dark] .btg-header {
  border-bottom: 2px solid #db9a1e;
}

[data-theme=dark] .btg-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.btg,
.nav-pills > li > a:hover .btg,
.nav-pills > li > a:focus .btg {
  color: #db9a1e;
}

[data-theme=dark] .nav-pills .nav-link.btg.active,
.nav-pills .nav-link:hover.btg.active,
.nav-pills .nav-link:focus.btg.active {
  color: #e5e5e5;
  background-color: #db9a1e;
}

/* generic */
[data-theme=dark] .neo-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .neo-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .neo-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .neo-border-promo {
  border: 3px solid #00ae1d !important;
}

[data-theme=dark] .neo-border-highlight {
  border: 3px solid #00ae1d;
  box-shadow: 0 0 20px 0 #00ae1d !important;
}

[data-theme=dark] .neo-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .neo-button {
  background-color: #00ae1d;
  color: #fff;
}

[data-theme=dark] .neo-button:hover {
  box-shadow: 0 0 10px 0 #00ae1d !important;
}

[data-theme=dark] .neo-header {
  border-bottom: 2px solid #00ae1d;
}

[data-theme=dark] .neo-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.neo,
.nav-pills > li > a:hover .neo,
.nav-pills > li > a:focus .neo {
  color: #00ae1d;
}

[data-theme=dark] .nav-pills .nav-link.neo.active,
.nav-pills .nav-link:hover.neo.active,
.nav-pills .nav-link:focus.neo.active {
  color: #e5e5e5;
  background-color: #00ae1d;
}

/* generic */
[data-theme=dark] .gas-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .gas-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .gas-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .gas-border-promo {
  border: 3px solid #00ae1d !important;
}

[data-theme=dark] .gas-border-highlight {
  border: 3px solid #00ae1d;
  box-shadow: 0 0 20px 0 #00ae1d !important;
}

[data-theme=dark] .gas-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .gas-button {
  background-color: #00ae1d;
  color: #fff;
}

[data-theme=dark] .gas-button:hover {
  box-shadow: 0 0 10px 0 #00ae1d !important;
}

[data-theme=dark] .gas-header {
  border-bottom: 2px solid #00ae1d;
}

[data-theme=dark] .gas-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.gas,
.nav-pills > li > a:hover .gas,
.nav-pills > li > a:focus .gas {
  color: #00ae1d;
}

[data-theme=dark] .nav-pills .nav-link.gas.active,
.nav-pills .nav-link:hover.gas.active,
.nav-pills .nav-link:focus.gas.active {
  color: #e5e5e5;
  background-color: #00ae1d;
}

/* generic */
[data-theme=dark] .btcp-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .btcp-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .btcp-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .btcp-border-promo {
  border: 3px solid #272d63 !important;
}

[data-theme=dark] .btcp-border-highlight {
  border: 3px solid #272d63;
  box-shadow: 0 0 20px 0 #272d63 !important;
}

[data-theme=dark] .btcp-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .btcp-button {
  background-color: #272d63;
  color: #fff;
}

[data-theme=dark] .btcp-button:hover {
  box-shadow: 0 0 10px 0 #272d63 !important;
}

[data-theme=dark] .btcp-header {
  border-bottom: 2px solid #272d63;
}

[data-theme=dark] .btcp-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.btcp,
.nav-pills > li > a:hover .btcp,
.nav-pills > li > a:focus .btcp {
  color: #272d63;
}

[data-theme=dark] .nav-pills .nav-link.btcp.active,
.nav-pills .nav-link:hover.btcp.active,
.nav-pills .nav-link:focus.btcp.active {
  color: #e5e5e5;
  background-color: #272d63;
}

/* generic */
[data-theme=dark] .usdt-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .usdt-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .usdt-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .usdt-border-promo {
  border: 3px solid #22a079 !important;
}

[data-theme=dark] .usdt-border-highlight {
  border: 3px solid #22a079;
  box-shadow: 0 0 20px 0 #22a079 !important;
}

[data-theme=dark] .usdt-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .usdt-button {
  background-color: #22a079;
  color: #fff;
}

[data-theme=dark] .usdt-button:hover {
  box-shadow: 0 0 10px 0 #22a079 !important;
}

[data-theme=dark] .usdt-header {
  border-bottom: 2px solid #22a079;
}

[data-theme=dark] .usdt-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.usdt,
.nav-pills > li > a:hover .usdt,
.nav-pills > li > a:focus .usdt {
  color: #22a079;
}

[data-theme=dark] .nav-pills .nav-link.usdt.active,
.nav-pills .nav-link:hover.usdt.active,
.nav-pills .nav-link:focus.usdt.active {
  color: #e5e5e5;
  background-color: #22a079;
}

/* generic */
[data-theme=dark] .nmc-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .nmc-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .nmc-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .nmc-border-promo {
  border: 3px solid #27A9E3 !important;
}

[data-theme=dark] .nmc-border-highlight {
  border: 3px solid #27A9E3;
  box-shadow: 0 0 20px 0 #27A9E3 !important;
}

[data-theme=dark] .nmc-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .nmc-button {
  background-color: #27A9E3;
  color: #fff;
}

[data-theme=dark] .nmc-button:hover {
  box-shadow: 0 0 10px 0 #27A9E3 !important;
}

[data-theme=dark] .nmc-header {
  border-bottom: 2px solid #27A9E3;
}

[data-theme=dark] .nmc-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.nmc,
.nav-pills > li > a:hover .nmc,
.nav-pills > li > a:focus .nmc {
  color: #27A9E3;
}

[data-theme=dark] .nav-pills .nav-link.nmc.active,
.nav-pills .nav-link:hover.nmc.active,
.nav-pills .nav-link:focus.nmc.active {
  color: #e5e5e5;
  background-color: #27A9E3;
}

/* generic */
[data-theme=dark] .xrp-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .xrp-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .xrp-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .xrp-border-promo {
  border: 3px solid #0073AB !important;
}

[data-theme=dark] .xrp-border-highlight {
  border: 3px solid #0073AB;
  box-shadow: 0 0 20px 0 #0073AB !important;
}

[data-theme=dark] .xrp-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .xrp-button {
  background-color: #0073AB;
  color: #fff;
}

[data-theme=dark] .xrp-button:hover {
  box-shadow: 0 0 10px 0 #0073AB !important;
}

[data-theme=dark] .xrp-header {
  border-bottom: 2px solid #0073AB;
}

[data-theme=dark] .xrp-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.xrp,
.nav-pills > li > a:hover .xrp,
.nav-pills > li > a:focus .xrp {
  color: #0073AB;
}

[data-theme=dark] .nav-pills .nav-link.xrp.active,
.nav-pills .nav-link:hover.xrp.active,
.nav-pills .nav-link:focus.xrp.active {
  color: #e5e5e5;
  background-color: #0073AB;
}

/* generic */
[data-theme=dark] .xlm-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .xlm-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .xlm-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .xlm-border-promo {
  border: 3px solid #08b5e5 !important;
}

[data-theme=dark] .xlm-border-highlight {
  border: 3px solid #08b5e5;
  box-shadow: 0 0 20px 0 #08b5e5 !important;
}

[data-theme=dark] .xlm-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .xlm-button {
  background-color: #08b5e5;
  color: #fff;
}

[data-theme=dark] .xlm-button:hover {
  box-shadow: 0 0 10px 0 #08b5e5 !important;
}

[data-theme=dark] .xlm-header {
  border-bottom: 2px solid #08b5e5;
}

[data-theme=dark] .xlm-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.xlm,
.nav-pills > li > a:hover .xlm,
.nav-pills > li > a:focus .xlm {
  color: #08b5e5;
}

[data-theme=dark] .nav-pills .nav-link.xlm.active,
.nav-pills .nav-link:hover.xlm.active,
.nav-pills .nav-link:focus.xlm.active {
  color: #e5e5e5;
  background-color: #08b5e5;
}

/* generic */
[data-theme=dark] .ada-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .ada-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .ada-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .ada-border-promo {
  border: 3px solid #33adad !important;
}

[data-theme=dark] .ada-border-highlight {
  border: 3px solid #33adad;
  box-shadow: 0 0 20px 0 #33adad !important;
}

[data-theme=dark] .ada-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .ada-button {
  background-color: #33adad;
  color: #fff;
}

[data-theme=dark] .ada-button:hover {
  box-shadow: 0 0 10px 0 #33adad !important;
}

[data-theme=dark] .ada-header {
  border-bottom: 2px solid #33adad;
}

[data-theme=dark] .ada-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.ada,
.nav-pills > li > a:hover .ada,
.nav-pills > li > a:focus .ada {
  color: #33adad;
}

[data-theme=dark] .nav-pills .nav-link.ada.active,
.nav-pills .nav-link:hover.ada.active,
.nav-pills .nav-link:focus.ada.active {
  color: #e5e5e5;
  background-color: #33adad;
}

/* generic */
[data-theme=dark] .trx-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .trx-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .trx-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .trx-border-promo {
  border: 3px solid #780000 !important;
}

[data-theme=dark] .trx-border-highlight {
  border: 3px solid #780000;
  box-shadow: 0 0 20px 0 #780000 !important;
}

[data-theme=dark] .trx-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .trx-button {
  background-color: #780000;
  color: #fff;
}

[data-theme=dark] .trx-button:hover {
  box-shadow: 0 0 10px 0 #780000 !important;
}

[data-theme=dark] .trx-header {
  border-bottom: 2px solid #780000;
}

[data-theme=dark] .trx-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.trx,
.nav-pills > li > a:hover .trx,
.nav-pills > li > a:focus .trx {
  color: #780000;
}

[data-theme=dark] .nav-pills .nav-link.trx.active,
.nav-pills .nav-link:hover.trx.active,
.nav-pills .nav-link:focus.trx.active {
  color: #e5e5e5;
  background-color: #780000;
}

/* generic */
[data-theme=dark] .doge-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .doge-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .doge-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .doge-border-promo {
  border: 3px solid #ba9f33 !important;
}

[data-theme=dark] .doge-border-highlight {
  border: 3px solid #ba9f33;
  box-shadow: 0 0 20px 0 #ba9f33 !important;
}

[data-theme=dark] .doge-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .doge-button {
  background-color: #ba9f33;
  color: #fff;
}

[data-theme=dark] .doge-button:hover {
  box-shadow: 0 0 10px 0 #ba9f33 !important;
}

[data-theme=dark] .doge-header {
  border-bottom: 2px solid #ba9f33;
}

[data-theme=dark] .doge-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.doge,
.nav-pills > li > a:hover .doge,
.nav-pills > li > a:focus .doge {
  color: #ba9f33;
}

[data-theme=dark] .nav-pills .nav-link.doge.active,
.nav-pills .nav-link:hover.doge.active,
.nav-pills .nav-link:focus.doge.active {
  color: #e5e5e5;
  background-color: #ba9f33;
}

/* generic */
[data-theme=dark] .btt-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .btt-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .btt-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .btt-border-promo {
  border: 3px solid #c53460 !important;
}

[data-theme=dark] .btt-border-highlight {
  border: 3px solid #c53460;
  box-shadow: 0 0 20px 0 #c53460 !important;
}

[data-theme=dark] .btt-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .btt-button {
  background-color: #c53460;
  color: #fff;
}

[data-theme=dark] .btt-button:hover {
  box-shadow: 0 0 10px 0 #c53460 !important;
}

[data-theme=dark] .btt-header {
  border-bottom: 2px solid #c53460;
}

[data-theme=dark] .btt-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.btt,
.nav-pills > li > a:hover .btt,
.nav-pills > li > a:focus .btt {
  color: #c53460;
}

[data-theme=dark] .nav-pills .nav-link.btt.active,
.nav-pills .nav-link:hover.btt.active,
.nav-pills .nav-link:focus.btt.active {
  color: #e5e5e5;
  background-color: #c53460;
}

/* generic */
[data-theme=dark] .xau-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .xau-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .xau-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .xau-border-promo {
  border: 3px solid #db9a1e !important;
}

[data-theme=dark] .xau-border-highlight {
  border: 3px solid #db9a1e;
  box-shadow: 0 0 20px 0 #db9a1e !important;
}

[data-theme=dark] .xau-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .xau-button {
  background-color: #db9a1e;
  color: #fff;
}

[data-theme=dark] .xau-button:hover {
  box-shadow: 0 0 10px 0 #db9a1e !important;
}

[data-theme=dark] .xau-header {
  border-bottom: 2px solid #db9a1e;
}

[data-theme=dark] .xau-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.xau,
.nav-pills > li > a:hover .xau,
.nav-pills > li > a:focus .xau {
  color: #db9a1e;
}

[data-theme=dark] .nav-pills .nav-link.xau.active,
.nav-pills .nav-link:hover.xau.active,
.nav-pills .nav-link:focus.xau.active {
  color: #e5e5e5;
  background-color: #db9a1e;
}

/* generic */
[data-theme=dark] .xag-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .xag-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .xag-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .xag-border-promo {
  border: 3px solid #C0C0C0 !important;
}

[data-theme=dark] .xag-border-highlight {
  border: 3px solid #C0C0C0;
  box-shadow: 0 0 20px 0 #C0C0C0 !important;
}

[data-theme=dark] .xag-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .xag-button {
  background-color: #C0C0C0;
  color: #fff;
}

[data-theme=dark] .xag-button:hover {
  box-shadow: 0 0 10px 0 #C0C0C0 !important;
}

[data-theme=dark] .xag-header {
  border-bottom: 2px solid #C0C0C0;
}

[data-theme=dark] .xag-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.xag,
.nav-pills > li > a:hover .xag,
.nav-pills > li > a:focus .xag {
  color: #C0C0C0;
}

[data-theme=dark] .nav-pills .nav-link.xag.active,
.nav-pills .nav-link:hover.xag.active,
.nav-pills .nav-link:focus.xag.active {
  color: #e5e5e5;
  background-color: #C0C0C0;
}

/* generic */
[data-theme=dark] .bat-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .bat-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .bat-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .bat-border-promo {
  border: 3px solid #870065 !important;
}

[data-theme=dark] .bat-border-highlight {
  border: 3px solid #870065;
  box-shadow: 0 0 20px 0 #870065 !important;
}

[data-theme=dark] .bat-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .bat-button {
  background-color: #870065;
  color: #fff;
}

[data-theme=dark] .bat-button:hover {
  box-shadow: 0 0 10px 0 #870065 !important;
}

[data-theme=dark] .bat-header {
  border-bottom: 2px solid #870065;
}

[data-theme=dark] .bat-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.bat,
.nav-pills > li > a:hover .bat,
.nav-pills > li > a:focus .bat {
  color: #870065;
}

[data-theme=dark] .nav-pills .nav-link.bat.active,
.nav-pills .nav-link:hover.bat.active,
.nav-pills .nav-link:focus.bat.active {
  color: #e5e5e5;
  background-color: #870065;
}

/* generic */
[data-theme=dark] .comp-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .comp-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .comp-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .comp-border-promo {
  border: 3px solid #00D395 !important;
}

[data-theme=dark] .comp-border-highlight {
  border: 3px solid #00D395;
  box-shadow: 0 0 20px 0 #00D395 !important;
}

[data-theme=dark] .comp-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .comp-button {
  background-color: #00D395;
  color: #fff;
}

[data-theme=dark] .comp-button:hover {
  box-shadow: 0 0 10px 0 #00D395 !important;
}

[data-theme=dark] .comp-header {
  border-bottom: 2px solid #00D395;
}

[data-theme=dark] .comp-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.comp,
.nav-pills > li > a:hover .comp,
.nav-pills > li > a:focus .comp {
  color: #00D395;
}

[data-theme=dark] .nav-pills .nav-link.comp.active,
.nav-pills .nav-link:hover.comp.active,
.nav-pills .nav-link:focus.comp.active {
  color: #e5e5e5;
  background-color: #00D395;
}

/* generic */
[data-theme=dark] .dai-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .dai-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .dai-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .dai-border-promo {
  border: 3px solid #f8b249 !important;
}

[data-theme=dark] .dai-border-highlight {
  border: 3px solid #f8b249;
  box-shadow: 0 0 20px 0 #f8b249 !important;
}

[data-theme=dark] .dai-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .dai-button {
  background-color: #f8b249;
  color: #fff;
}

[data-theme=dark] .dai-button:hover {
  box-shadow: 0 0 10px 0 #f8b249 !important;
}

[data-theme=dark] .dai-header {
  border-bottom: 2px solid #f8b249;
}

[data-theme=dark] .dai-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.dai,
.nav-pills > li > a:hover .dai,
.nav-pills > li > a:focus .dai {
  color: #f8b249;
}

[data-theme=dark] .nav-pills .nav-link.dai.active,
.nav-pills .nav-link:hover.dai.active,
.nav-pills .nav-link:focus.dai.active {
  color: #e5e5e5;
  background-color: #f8b249;
}

/* generic */
[data-theme=dark] .mkr-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .mkr-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .mkr-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .mkr-border-promo {
  border: 3px solid #1aab9b !important;
}

[data-theme=dark] .mkr-border-highlight {
  border: 3px solid #1aab9b;
  box-shadow: 0 0 20px 0 #1aab9b !important;
}

[data-theme=dark] .mkr-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .mkr-button {
  background-color: #1aab9b;
  color: #fff;
}

[data-theme=dark] .mkr-button:hover {
  box-shadow: 0 0 10px 0 #1aab9b !important;
}

[data-theme=dark] .mkr-header {
  border-bottom: 2px solid #1aab9b;
}

[data-theme=dark] .mkr-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.mkr,
.nav-pills > li > a:hover .mkr,
.nav-pills > li > a:focus .mkr {
  color: #1aab9b;
}

[data-theme=dark] .nav-pills .nav-link.mkr.active,
.nav-pills .nav-link:hover.mkr.active,
.nav-pills .nav-link:focus.mkr.active {
  color: #e5e5e5;
  background-color: #1aab9b;
}

/* generic */
[data-theme=dark] .wbtc-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .wbtc-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .wbtc-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .wbtc-border-promo {
  border: 3px solid #f09242 !important;
}

[data-theme=dark] .wbtc-border-highlight {
  border: 3px solid #f09242;
  box-shadow: 0 0 20px 0 #f09242 !important;
}

[data-theme=dark] .wbtc-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .wbtc-button {
  background-color: #f09242;
  color: #fff;
}

[data-theme=dark] .wbtc-button:hover {
  box-shadow: 0 0 10px 0 #f09242 !important;
}

[data-theme=dark] .wbtc-header {
  border-bottom: 2px solid #f09242;
}

[data-theme=dark] .wbtc-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.wbtc,
.nav-pills > li > a:hover .wbtc,
.nav-pills > li > a:focus .wbtc {
  color: #f09242;
}

[data-theme=dark] .nav-pills .nav-link.wbtc.active,
.nav-pills .nav-link:hover.wbtc.active,
.nav-pills .nav-link:focus.wbtc.active {
  color: #e5e5e5;
  background-color: #f09242;
}

/* generic */
[data-theme=dark] .link-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .link-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .link-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .link-border-promo {
  border: 3px solid #2a5ada !important;
}

[data-theme=dark] .link-border-highlight {
  border: 3px solid #2a5ada;
  box-shadow: 0 0 20px 0 #2a5ada !important;
}

[data-theme=dark] .link-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .link-button {
  background-color: #2a5ada;
  color: #fff;
}

[data-theme=dark] .link-button:hover {
  box-shadow: 0 0 10px 0 #2a5ada !important;
}

[data-theme=dark] .link-header {
  border-bottom: 2px solid #2a5ada;
}

[data-theme=dark] .link-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.link,
.nav-pills > li > a:hover .link,
.nav-pills > li > a:focus .link {
  color: #2a5ada;
}

[data-theme=dark] .nav-pills .nav-link.link.active,
.nav-pills .nav-link:hover.link.active,
.nav-pills .nav-link:focus.link.active {
  color: #e5e5e5;
  background-color: #2a5ada;
}

/* generic */
[data-theme=dark] .xtz-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .xtz-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .xtz-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .xtz-border-promo {
  border: 3px solid #2c7df7 !important;
}

[data-theme=dark] .xtz-border-highlight {
  border: 3px solid #2c7df7;
  box-shadow: 0 0 20px 0 #2c7df7 !important;
}

[data-theme=dark] .xtz-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .xtz-button {
  background-color: #2c7df7;
  color: #fff;
}

[data-theme=dark] .xtz-button:hover {
  box-shadow: 0 0 10px 0 #2c7df7 !important;
}

[data-theme=dark] .xtz-header {
  border-bottom: 2px solid #2c7df7;
}

[data-theme=dark] .xtz-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.xtz,
.nav-pills > li > a:hover .xtz,
.nav-pills > li > a:focus .xtz {
  color: #2c7df7;
}

[data-theme=dark] .nav-pills .nav-link.xtz.active,
.nav-pills .nav-link:hover.xtz.active,
.nav-pills .nav-link:focus.xtz.active {
  color: #e5e5e5;
  background-color: #2c7df7;
}

/* generic */
[data-theme=dark] .dot-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .dot-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .dot-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .dot-border-promo {
  border: 3px solid #e6007a !important;
}

[data-theme=dark] .dot-border-highlight {
  border: 3px solid #e6007a;
  box-shadow: 0 0 20px 0 #e6007a !important;
}

[data-theme=dark] .dot-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .dot-button {
  background-color: #e6007a;
  color: #fff;
}

[data-theme=dark] .dot-button:hover {
  box-shadow: 0 0 10px 0 #e6007a !important;
}

[data-theme=dark] .dot-header {
  border-bottom: 2px solid #e6007a;
}

[data-theme=dark] .dot-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.dot,
.nav-pills > li > a:hover .dot,
.nav-pills > li > a:focus .dot {
  color: #e6007a;
}

[data-theme=dark] .nav-pills .nav-link.dot.active,
.nav-pills .nav-link:hover.dot.active,
.nav-pills .nav-link:focus.dot.active {
  color: #e5e5e5;
  background-color: #e6007a;
}

/* generic */
[data-theme=dark] .bnb-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .bnb-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .bnb-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .bnb-border-promo {
  border: 3px solid #f3ba2f !important;
}

[data-theme=dark] .bnb-border-highlight {
  border: 3px solid #f3ba2f;
  box-shadow: 0 0 20px 0 #f3ba2f !important;
}

[data-theme=dark] .bnb-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .bnb-button {
  background-color: #f3ba2f;
  color: #fff;
}

[data-theme=dark] .bnb-button:hover {
  box-shadow: 0 0 10px 0 #f3ba2f !important;
}

[data-theme=dark] .bnb-header {
  border-bottom: 2px solid #f3ba2f;
}

[data-theme=dark] .bnb-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.bnb,
.nav-pills > li > a:hover .bnb,
.nav-pills > li > a:focus .bnb {
  color: #f3ba2f;
}

[data-theme=dark] .nav-pills .nav-link.bnb.active,
.nav-pills .nav-link:hover.bnb.active,
.nav-pills .nav-link:focus.bnb.active {
  color: #e5e5e5;
  background-color: #f3ba2f;
}

/* generic */
[data-theme=dark] .shib-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .shib-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .shib-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .shib-border-promo {
  border: 3px solid #ff0f1f !important;
}

[data-theme=dark] .shib-border-highlight {
  border: 3px solid #ff0f1f;
  box-shadow: 0 0 20px 0 #ff0f1f !important;
}

[data-theme=dark] .shib-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .shib-button {
  background-color: #ff0f1f;
  color: #fff;
}

[data-theme=dark] .shib-button:hover {
  box-shadow: 0 0 10px 0 #ff0f1f !important;
}

[data-theme=dark] .shib-header {
  border-bottom: 2px solid #ff0f1f;
}

[data-theme=dark] .shib-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.shib,
.nav-pills > li > a:hover .shib,
.nav-pills > li > a:focus .shib {
  color: #ff0f1f;
}

[data-theme=dark] .nav-pills .nav-link.shib.active,
.nav-pills .nav-link:hover.shib.active,
.nav-pills .nav-link:focus.shib.active {
  color: #e5e5e5;
  background-color: #ff0f1f;
}

/* generic */
[data-theme=dark] .matic-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .matic-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .matic-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .matic-border-promo {
  border: 3px solid #8247e5 !important;
}

[data-theme=dark] .matic-border-highlight {
  border: 3px solid #8247e5;
  box-shadow: 0 0 20px 0 #8247e5 !important;
}

[data-theme=dark] .matic-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .matic-button {
  background-color: #8247e5;
  color: #fff;
}

[data-theme=dark] .matic-button:hover {
  box-shadow: 0 0 10px 0 #8247e5 !important;
}

[data-theme=dark] .matic-header {
  border-bottom: 2px solid #8247e5;
}

[data-theme=dark] .matic-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.matic,
.nav-pills > li > a:hover .matic,
.nav-pills > li > a:focus .matic {
  color: #8247e5;
}

[data-theme=dark] .nav-pills .nav-link.matic.active,
.nav-pills .nav-link:hover.matic.active,
.nav-pills .nav-link:focus.matic.active {
  color: #e5e5e5;
  background-color: #8247e5;
}

/* generic */
[data-theme=dark] .sgb-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .sgb-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .sgb-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .sgb-border-promo {
  border: 3px solid #b35160 !important;
}

[data-theme=dark] .sgb-border-highlight {
  border: 3px solid #b35160;
  box-shadow: 0 0 20px 0 #b35160 !important;
}

[data-theme=dark] .sgb-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .sgb-button {
  background-color: #b35160;
  color: #fff;
}

[data-theme=dark] .sgb-button:hover {
  box-shadow: 0 0 10px 0 #b35160 !important;
}

[data-theme=dark] .sgb-header {
  border-bottom: 2px solid #b35160;
}

[data-theme=dark] .sgb-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.sgb,
.nav-pills > li > a:hover .sgb,
.nav-pills > li > a:focus .sgb {
  color: #b35160;
}

[data-theme=dark] .nav-pills .nav-link.sgb.active,
.nav-pills .nav-link:hover.sgb.active,
.nav-pills .nav-link:focus.sgb.active {
  color: #e5e5e5;
  background-color: #b35160;
}

/* generic */
[data-theme=dark] .sol-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .sol-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .sol-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .sol-border-promo {
  border: 3px solid #1dd79b !important;
}

[data-theme=dark] .sol-border-highlight {
  border: 3px solid #1dd79b;
  box-shadow: 0 0 20px 0 #1dd79b !important;
}

[data-theme=dark] .sol-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .sol-button {
  background-color: #1dd79b;
  color: #fff;
}

[data-theme=dark] .sol-button:hover {
  box-shadow: 0 0 10px 0 #1dd79b !important;
}

[data-theme=dark] .sol-header {
  border-bottom: 2px solid #1dd79b;
}

[data-theme=dark] .sol-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.sol,
.nav-pills > li > a:hover .sol,
.nav-pills > li > a:focus .sol {
  color: #1dd79b;
}

[data-theme=dark] .nav-pills .nav-link.sol.active,
.nav-pills .nav-link:hover.sol.active,
.nav-pills .nav-link:focus.sol.active {
  color: #e5e5e5;
  background-color: #1dd79b;
}

/* generic */
[data-theme=dark] .usdc-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .usdc-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .usdc-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .usdc-border-promo {
  border: 3px solid #2775ca !important;
}

[data-theme=dark] .usdc-border-highlight {
  border: 3px solid #2775ca;
  box-shadow: 0 0 20px 0 #2775ca !important;
}

[data-theme=dark] .usdc-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .usdc-button {
  background-color: #2775ca;
  color: #fff;
}

[data-theme=dark] .usdc-button:hover {
  box-shadow: 0 0 10px 0 #2775ca !important;
}

[data-theme=dark] .usdc-header {
  border-bottom: 2px solid #2775ca;
}

[data-theme=dark] .usdc-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.usdc,
.nav-pills > li > a:hover .usdc,
.nav-pills > li > a:focus .usdc {
  color: #2775ca;
}

[data-theme=dark] .nav-pills .nav-link.usdc.active,
.nav-pills .nav-link:hover.usdc.active,
.nav-pills .nav-link:focus.usdc.active {
  color: #e5e5e5;
  background-color: #2775ca;
}

/* generic */
[data-theme=dark] .ethw-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .ethw-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .ethw-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .ethw-border-promo {
  border: 3px solid #3956de !important;
}

[data-theme=dark] .ethw-border-highlight {
  border: 3px solid #3956de;
  box-shadow: 0 0 20px 0 #3956de !important;
}

[data-theme=dark] .ethw-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .ethw-button {
  background-color: #3956de;
  color: #fff;
}

[data-theme=dark] .ethw-button:hover {
  box-shadow: 0 0 10px 0 #3956de !important;
}

[data-theme=dark] .ethw-header {
  border-bottom: 2px solid #3956de;
}

[data-theme=dark] .ethw-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.ethw,
.nav-pills > li > a:hover .ethw,
.nav-pills > li > a:focus .ethw {
  color: #3956de;
}

[data-theme=dark] .nav-pills .nav-link.ethw.active,
.nav-pills .nav-link:hover.ethw.active,
.nav-pills .nav-link:focus.ethw.active {
  color: #e5e5e5;
  background-color: #3956de;
}

/* generic */
[data-theme=dark] .flr-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .flr-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .flr-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .flr-border-promo {
  border: 3px solid #e62058 !important;
}

[data-theme=dark] .flr-border-highlight {
  border: 3px solid #e62058;
  box-shadow: 0 0 20px 0 #e62058 !important;
}

[data-theme=dark] .flr-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .flr-button {
  background-color: #e62058;
  color: #fff;
}

[data-theme=dark] .flr-button:hover {
  box-shadow: 0 0 10px 0 #e62058 !important;
}

[data-theme=dark] .flr-header {
  border-bottom: 2px solid #e62058;
}

[data-theme=dark] .flr-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.flr,
.nav-pills > li > a:hover .flr,
.nav-pills > li > a:focus .flr {
  color: #e62058;
}

[data-theme=dark] .nav-pills .nav-link.flr.active,
.nav-pills .nav-link:hover.flr.active,
.nav-pills .nav-link:focus.flr.active {
  color: #e5e5e5;
  background-color: #e62058;
}

/* generic */
[data-theme=dark] .wld-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .wld-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .wld-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .wld-border-promo {
  border: 3px solid #558ce7 !important;
}

[data-theme=dark] .wld-border-highlight {
  border: 3px solid #558ce7;
  box-shadow: 0 0 20px 0 #558ce7 !important;
}

[data-theme=dark] .wld-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .wld-button {
  background-color: #558ce7;
  color: #fff;
}

[data-theme=dark] .wld-button:hover {
  box-shadow: 0 0 10px 0 #558ce7 !important;
}

[data-theme=dark] .wld-header {
  border-bottom: 2px solid #558ce7;
}

[data-theme=dark] .wld-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.wld,
.nav-pills > li > a:hover .wld,
.nav-pills > li > a:focus .wld {
  color: #558ce7;
}

[data-theme=dark] .nav-pills .nav-link.wld.active,
.nav-pills .nav-link:hover.wld.active,
.nav-pills .nav-link:focus.wld.active {
  color: #e5e5e5;
  background-color: #558ce7;
}

/* generic */
[data-theme=dark] .atom-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .atom-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .atom-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .atom-border-promo {
  border: 3px solid #9236ac !important;
}

[data-theme=dark] .atom-border-highlight {
  border: 3px solid #9236ac;
  box-shadow: 0 0 20px 0 #9236ac !important;
}

[data-theme=dark] .atom-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .atom-button {
  background-color: #9236ac;
  color: #fff;
}

[data-theme=dark] .atom-button:hover {
  box-shadow: 0 0 10px 0 #9236ac !important;
}

[data-theme=dark] .atom-header {
  border-bottom: 2px solid #9236ac;
}

[data-theme=dark] .atom-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.atom,
.nav-pills > li > a:hover .atom,
.nav-pills > li > a:focus .atom {
  color: #9236ac;
}

[data-theme=dark] .nav-pills .nav-link.atom.active,
.nav-pills .nav-link:hover.atom.active,
.nav-pills .nav-link:focus.atom.active {
  color: #e5e5e5;
  background-color: #9236ac;
}

/* generic */
[data-theme=dark] .kas-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .kas-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .kas-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .kas-border-promo {
  border: 3px solid #8ec5b9 !important;
}

[data-theme=dark] .kas-border-highlight {
  border: 3px solid #8ec5b9;
  box-shadow: 0 0 20px 0 #8ec5b9 !important;
}

[data-theme=dark] .kas-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .kas-button {
  background-color: #8ec5b9;
  color: #fff;
}

[data-theme=dark] .kas-button:hover {
  box-shadow: 0 0 10px 0 #8ec5b9 !important;
}

[data-theme=dark] .kas-header {
  border-bottom: 2px solid #8ec5b9;
}

[data-theme=dark] .kas-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.kas,
.nav-pills > li > a:hover .kas,
.nav-pills > li > a:focus .kas {
  color: #8ec5b9;
}

[data-theme=dark] .nav-pills .nav-link.kas.active,
.nav-pills .nav-link:hover.kas.active,
.nav-pills .nav-link:focus.kas.active {
  color: #e5e5e5;
  background-color: #8ec5b9;
}

/* generic */
[data-theme=dark] .pepe-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .pepe-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .pepe-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .pepe-border-promo {
  border: 3px solid #689448 !important;
}

[data-theme=dark] .pepe-border-highlight {
  border: 3px solid #689448;
  box-shadow: 0 0 20px 0 #689448 !important;
}

[data-theme=dark] .pepe-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .pepe-button {
  background-color: #689448;
  color: #fff;
}

[data-theme=dark] .pepe-button:hover {
  box-shadow: 0 0 10px 0 #689448 !important;
}

[data-theme=dark] .pepe-header {
  border-bottom: 2px solid #689448;
}

[data-theme=dark] .pepe-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.pepe,
.nav-pills > li > a:hover .pepe,
.nav-pills > li > a:focus .pepe {
  color: #689448;
}

[data-theme=dark] .nav-pills .nav-link.pepe.active,
.nav-pills .nav-link:hover.pepe.active,
.nav-pills .nav-link:focus.pepe.active {
  color: #e5e5e5;
  background-color: #689448;
}

/* generic */
[data-theme=dark] .sui-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .sui-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .sui-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .sui-border-promo {
  border: 3px solid #4da2ff !important;
}

[data-theme=dark] .sui-border-highlight {
  border: 3px solid #4da2ff;
  box-shadow: 0 0 20px 0 #4da2ff !important;
}

[data-theme=dark] .sui-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .sui-button {
  background-color: #4da2ff;
  color: #fff;
}

[data-theme=dark] .sui-button:hover {
  box-shadow: 0 0 10px 0 #4da2ff !important;
}

[data-theme=dark] .sui-header {
  border-bottom: 2px solid #4da2ff;
}

[data-theme=dark] .sui-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.sui,
.nav-pills > li > a:hover .sui,
.nav-pills > li > a:focus .sui {
  color: #4da2ff;
}

[data-theme=dark] .nav-pills .nav-link.sui.active,
.nav-pills .nav-link:hover.sui.active,
.nav-pills .nav-link:focus.sui.active {
  color: #e5e5e5;
  background-color: #4da2ff;
}

/* generic */
[data-theme=dark] .avax-background {
  background-color: #242b32;
  color: #e5e5e5;
}

[data-theme=dark] .avax-background-dark {
  background-color: #242b32;
}

[data-theme=dark] .avax-border {
  border: 1px solid #242b32;
  box-shadow: 0 0 0 0;
}

[data-theme=dark] .avax-border-promo {
  border: 3px solid #e84142 !important;
}

[data-theme=dark] .avax-border-highlight {
  border: 3px solid #e84142;
  box-shadow: 0 0 20px 0 #e84142 !important;
}

[data-theme=dark] .avax-border-dark {
  border: 1px solid #242b32;
}

/* buttons */
[data-theme=dark] .avax-button {
  background-color: #e84142;
  color: #fff;
}

[data-theme=dark] .avax-button:hover {
  box-shadow: 0 0 10px 0 #e84142 !important;
}

[data-theme=dark] .avax-header {
  border-bottom: 2px solid #e84142;
}

[data-theme=dark] .avax-chart-volume {
  background-color: #c0c0c0;
}

/* navigation */
[data-theme=dark] .nav-pills > li > a.avax,
.nav-pills > li > a:hover .avax,
.nav-pills > li > a:focus .avax {
  color: #e84142;
}

[data-theme=dark] .nav-pills .nav-link.avax.active,
.nav-pills .nav-link:hover.avax.active,
.nav-pills .nav-link:focus.avax.active {
  color: #e5e5e5;
  background-color: #e84142;
}