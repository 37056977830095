/*
vars
*/
/*
general
*/
[data-theme=dark] .section-clean {
  background-color: #242b32 !important;
  -webkit-box-shadow: 0px 0px 50px #000 !important;
  -moz-box-shadow: 0px 0px 50px #000 !important;
  box-shadow: 0px 0px 50px #000 !important;
}

[data-theme=dark] #buyEstimateView, [data-theme=dark] #sellEstimateView {
  color: #e5e5e5 !important;
  background-color: #242b32 !important;
}

[data-theme=dark] .card {
  background-color: #242b32 !important;
}

[data-theme=dark] .card-header h3 {
  color: #e5e5e5 !important;
}

[data-theme=dark] .interest, [data-theme=dark] .capital {
  color: #e5e5e5 !important;
}

[data-theme=dark] .text-muted {
  color: #e5e5e5 !important;
}

[data-theme=dark] .text-muted b {
  font-weight: 700 !important;
}

[data-theme=dark] .interest, [data-theme=dark] .capital, [data-theme=dark] .existingoption b {
  color: #e5e5e5 !important;
}

[data-theme=dark] .loansblock {
  -webkit-box-shadow: 0px 0px 20px #000 !important;
  -moz-box-shadow: 0px 0px 20px #000 !important;
  box-shadow: 0px 0px 20px #000 !important;
}

[data-theme=dark] .loansblocksep {
  border-bottom: 1px solid #000 !important;
}

[data-theme=dark] .loansstep {
  color: #394046 !important;
}

/* buttons */
[data-theme=dark] .btn-success:hover {
  box-shadow: 0 0 10px 0 #28a745 !important;
  border: 1px solid #0a2c12 !important;
}

[data-theme=dark] .btn-danger:hover {
  box-shadow: 0 0 10px 0 #dc3545 !important;
  border: 1px solid #66121a !important;
}

[data-theme=dark] .btn-primary:hover {
  box-shadow: 0 0 10px 0 #007bff !important;
  border: 1px solid #003166 !important;
}

/*
gradient animation
*/
:root {
  --gradient-promo-dark: linear-gradient(45deg, #394046, #2d3338, #394046);
}

[data-theme=dark] .backgroundpromo {
  background-image: var(--gradient-promo-dark);
}