/* switch */
.switch {
  position: relative;
  top: -5px;
  overflow: hidden;
  width: 6rem;
  height: 3rem;
}

.switch input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.switch label {
  cursor: pointer;
}

.background {
  z-index: 1;
  position: absolute;
  width: 4.5rem;
  height: 2.5rem;
  border-radius: 15px;
  border: 0.2rem solid #202020;
  background: linear-gradient(to right, #484848 0%, #202020 100%);
  transition: all 0.3s;
}

.stars1,
.stars2 {
  position: absolute;
  height: 0.3rem;
  width: 0.3rem;
  background: #FFFFFF;
  border-radius: 15px;
  transition: 0.3s all ease;
}

.stars1 {
  top: 6px;
  right: 23px;
}

.stars2 {
  top: 40px;
  right: 48px;
}

.stars1:after,
.stars1:before,
.stars2:after,
.stars2:before {
  position: absolute;
  content: "";
  display: block;
  height: 0.2rem;
  width: 0.2rem;
  background: #FFFFFF;
  border-radius: 50%;
  transition: 0.2s all ease;
}

.stars1:after {
  top: 8px;
  right: 20px;
}

.stars1:before {
  top: 18px;
  right: -12px;
}

.stars2:after {
  top: -8px;
  right: -16px;
}

.stars2:before {
  top: 6px;
  right: -26px;
}

.sun-moon {
  z-index: 2;
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem;
  background: #FFFDF2;
  border-radius: 50%;
  transition: all 0.5s ease;
  border: 0.2rem solid #DEE2C6;
}

.sun-moon .dots {
  position: absolute;
  top: 3px;
  left: 18px;
  height: 1rem;
  width: 1rem;
  background: #EFEEDB;
  border: 0.2rem solid #DEE2C6;
  border-radius: 50%;
  transition: 0.4s all ease;
}

.sun-moon .dots:after,
.sun-moon .dots:before {
  position: absolute;
  content: "";
  display: block;
  height: 0.2rem;
  width: 0.2rem;
  background: #EFEEDB;
  border: 0.2rem solid #DEE2C6;
  border-radius: 50%;
  transition: 0.4s all ease;
}

.sun-moon .dots:after {
  top: -4px;
  left: -26px;
}

.sun-moon .dots:before {
  top: 13px;
  left: -10px;
}

/* Transition to Sun */
.switch input:checked ~ .sun-moon {
  left: calc(100% - 4rem);
  background: #F5EC59;
  border-color: #E7C65C;
  transform: rotate(-25deg);
}

.switch input:checked ~ .sun-moon .dots,
.switch input:checked ~ .sun-moon .dots:after,
.switch input:checked ~ .sun-moon .dots:before {
  background: #FFFFFF;
  border-color: #FFFFFF;
}

.switch input:checked ~ .sun-moon .dots {
  height: 1.5rem;
  width: 1.5rem;
  top: 0px;
  left: -20px;
  transform: rotate(25deg);
}

.switch input:checked ~ .sun-moon .dots:after {
  height: 0.65rem;
  width: 0.65rem;
  top: 2px;
  left: -12px;
}

.switch input:checked ~ .sun-moon .dots:before {
  height: 0.4rem;
  width: 0.4rem;
  top: 6px;
  left: 14px;
}

.switch input:checked ~ .background .stars1,
.switch input:checked ~ .background .stars2 {
  opacity: 0;
  transform: translateY(2rem);
}

.switch input:checked ~ .background {
  border: 0.25rem solid #78C1D5;
  background: linear-gradient(to right, #78C1D5 0%, #BBE7F5 100%);
}

/* override */
[data-theme=dark] {
  background-color: #242b32 !important;
  color: #e5e5e5 !important;
}

[data-theme=dark] .border, [data-theme=dark] .list-group-item {
  border-color: #e5e5e5 !important;
}

[data-theme=dark] input, [data-theme=dark] select, [data-theme=dark] .input-group-text, [data-theme=dark] .form-control {
  background-color: #20262d !important;
  color: #e5e5e5 !important;
}

[data-theme=dark] .navbar, [data-theme=dark] footer {
  background-image: url("") !important;
}

[data-theme=dark] footer {
  border-top: 1px solid #e5e5e5;
}

[data-theme=dark] #balance, [data-theme=dark] #copyright {
  background-color: #394046 !important;
  color: #e5e5e5 !important;
}

[data-theme=dark] .page-title {
  color: #e5e5e5 !important;
}

[data-theme=dark] .list-group-item {
  background-color: #242b32 !important;
}

[data-theme=dark] .custom-dropdown .dropdown-toggle, [data-theme=dark] .dropdown-menu {
  background-color: #242b32 !important;
  color: #e5e5e5 !important;
}

[data-theme=dark] .dropdown-menu li i {
  color: #e5e5e5 !important;
}

[data-theme=dark] .bg-light {
  background-color: #20262d !important;
  color: #e5e5e5 !important;
}

[data-theme=dark] #profileNav ul li:hover, [data-theme=dark] .profileNavHighlight {
  background-color: #20262d !important;
}

[data-theme=dark] .modal-content {
  background-color: #242b32 !important;
}

[data-theme=dark] .fa-exclamation-circle {
  color: #e60000;
}

[data-theme=dark] .fa-check-circle {
  color: #00b300;
}

[data-theme=dark] hr {
  color: #e5e5e5 !important;
  background-color: #e5e5e5 !important;
  border-color: #e5e5e5 !important;
}

[data-theme=dark] .swal2-title, [data-theme=dark] .swal2-content, [data-theme=dark] .swal2-container, [data-theme=dark] .swal2-modal {
  background: #242b32 !important;
  color: #fff !important;
}

/*  forms */
[data-theme=dark] .input-group-prepend {
  color: #e5e5e5 !important;
  border-left: 1px solid #242b32 !important;
}

[data-theme=dark] .input-group-append {
  border-right: 1px solid #242b32 !important;
}

/* table layouts */
[data-theme=dark] .table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #20262d !important;
}

[data-theme=dark] .table thead th {
  border-bottom: 1px solid #20262d !important;
}

[data-theme=dark] .table th {
  box-shadow: 0px 0px !important;
  -moz-box-shadow: 0px 0px !important;
  -webkit-box-shadow: 0px 0px !important;
}

/* news, media, blogs */
[data-theme=dark] article {
  border: 1px solid #e5e5e5 !important;
}

[data-theme=dark] article > h2, [data-theme=dark] article .act-border, [data-theme=dark] article > div:nth-of-type(1) {
  background-color: #e5e5e5 !important;
  border: 1px solid #e5e5e5 !important;
  color: #000 !important;
}

/* promo */
[data-theme=dark] .promobox {
  -webkit-box-shadow: 0px 0px 8px #000;
  -moz-box-shadow: 0px 0px 8px #000;
  box-shadow: 0px 0px 8px #000;
}

[data-theme=dark] .promobox:hover {
  transform: scale(1.15);
  -webkit-box-shadow: 0px 0px 25px #000;
  -moz-box-shadow: 0px 0px 25px #000;
  box-shadow: 0px 0px 25px #000;
}