[data-theme=dark] #depositOptions .card, [data-theme=dark] #withdrawOptions .card, [data-theme=dark] #profile .card {
  background-color: #242b32 !important;
  border: 1px solid #e5e5e5 !important;
}

[data-theme=dark] #depositOptions .card .card-header, [data-theme=dark] #withdrawOptions .card .card-header, [data-theme=dark] #profile .card .card-header {
  background-color: #242b32 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

[data-theme=dark] #listcrypto {
  background-image: linear-gradient(#242b32, #242b32);
}