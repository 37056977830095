[data-theme=dark] .table-striped th, [data-theme=dark] .table-striped thead {
  background-color: #242b32 !important;
  border-top: 1px solid #242b32 !important;
}

[data-theme=dark] .table a, [data-theme=dark] .table a:link, [data-theme=dark] .table a:visited, [data-theme=dark] .table a:hover {
  color: #e5e5e5 !important;
}

[data-theme=dark] .table td, [data-theme=dark] .table th {
  border-top: 1px solid #20262d !important;
}

[data-theme=dark] .table-hover tbody tr:hover td, [data-theme=dark] .table-hover tbody tr:hover td a, [data-theme=dark] .table-hover tbody tr:hover th {
  background-color: #4f555a !important;
}

[data-theme=dark] .table-striped th, [data-theme=dark] #coin_market_table th {
  background-color: #242b32 !important;
}

/* high charts override */
[data-theme=dark] .highcharts-background {
  fill: #242b32 !important;
}

[data-theme=dark] .labelColor {
  background-color: #fff !important;
}

[data-theme=dark] .candleColor {
  background-color: #b30000 !important;
}

[data-theme=dark] .candleUpColor {
  background-color: #00b300 !important;
}

[data-theme=dark] .candleLineColor {
  background-color: #b30000 !important;
}

[data-theme=dark] .candleUpLineColor {
  background-color: #00b300 !important;
}